import { stats as statsWidget } from "instantsearch.js/es/widgets";

export const stats = $("#stats").length
  ? statsWidget({
      container: "#stats",
      cssClasses: {
        root: "text-sm",
      },
      templates: {
        text(data, { html }) {
          let count = data.hasManyResults
            ? `${data.nbHits} products`
            : data.hasOneResult
            ? `1 products`
            : `no products`;

          return html`<span>${count}</span>`;
        },
      },
    })
  : null;
