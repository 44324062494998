import { sortBy as sortByWidget } from "instantsearch.js/es/widgets";

const items = [
  {
    label: "Featured",
    value: indexName,
  },
  {
    label: "Price (low to high)",
    value: "products_price_asc",
  },
  {
    label: "Price (high to low)",
    value: "products_price_desc",
  },
  {
    label: "Title (A to Z)",
    value: "products_title_asc",
  },
  {
    label: "Title (Z to A)",
    value: "products_title_desc",
  },
];

export const sortBy = $("#sort-by").length
  ? sortByWidget({
      container: "#sort-by",
      items,
      cssClasses: {
        select:
          "focus:border-black rounded-none text-sm focus:outline-none appearance-none cursor-pointer",
      },
    })
  : null;
