import { rangeSlider } from "instantsearch.js/es/widgets";

export const productPrice = $("#product-price").length
  ? rangeSlider({
      container: "#product-price",
      attribute: "price",
      step: 100,
      pips: false,
      tooltips: {
        format(value) {
          return `£${(Math.round(value / 100) * 100).toLocaleString()}`;
        },
      },
    })
  : null;
