import { stats } from "instantsearch.js/es/widgets";

export const statsTotal = $("#stats-total").length
  ? stats({
      container: "#stats-total",
      cssClasses: {
        root: "text-sm",
      },
      templates: {
        text(data, { html }) {
          let count = data.hasManyResults
            ? `${data.nbHits} products`
            : data.hasOneResult
            ? `1 products`
            : `no products`;
          let text = "";

          if (!data.hasNoResults && data.nbHits < data.hitsPerPage) {
            text += `${data.nbHits} / ${count}`;
          } else if (!data.hasNoResults) {
            text += `${data.hitsPerPage * (data.page + 1)} / ${count}`;
          }

          return html`<span>${text}</span>`;
        },
      },
    })
  : null;
