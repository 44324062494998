import { searchBox as searchBoxWidget } from "instantsearch.js/es/widgets";

export const searchBox = $("#searchbox").length
  ? searchBoxWidget({
      container: "#searchbox",
      placeholder: "Search for products...",
      showReset: false,
      cssClasses: {
        form: "relative max-w-full md:max-w-md",
        input:
          "block w-full p-3 pl-16 bg-gray-100 focus:bg-white border-2 border-gray-100 focus:border-blue-500 rounded-none text-gray-700 placeholder:text-black/25 focus:outline-none appearance-none",
        submit:
          "absolute top-0 left-0 p-3 hover:bg-gray-200 border-2 border-transparent transition",
        submitIcon: "w-6 h-6 p-1",
      },
    })
  : null;
