const searchWrapper = $("#nav-search");
const searchContainer = $("#nav-search .container");
const filter = $(".product-filter");

export function openNav(nav) {
  nav.removeClass("translate-x-full opacity-0");
}

export function closeNav(nav) {
  nav.addClass("translate-x-full opacity-0");
}

export function toggleSearch() {
  $(searchWrapper.hasClass("scale-y-0") ? openSearch() : closeSearch());
}

export function openSearch() {
  searchWrapper.removeClass("scale-y-0").addClass("scale-y-full");
  searchContainer.removeClass("opacity-0").addClass("opacity-100");
  openOverlay($("#nav-overlay"));
}

export function closeSearch() {
  searchWrapper.removeClass("scale-y-full").addClass("scale-y-0");
  searchContainer.removeClass("opacity-100").addClass("opacity-0");
  closeOverlay($("#nav-overlay"));
}

export function openOverlay(overlay) {
  overlay.removeClass("opacity-0 pointer-events-none").addClass("opacity-60");
}

export function closeOverlay(overlay) {
  overlay.removeClass("opacity-60").addClass("opacity-0 pointer-events-none");
}

export function openFilter() {
  filter.removeClass("translate-x-full opacity-0");
  openOverlay($("#filter-overlay"));
}

export function closeFilter() {
  filter.addClass("translate-x-full opacity-0");
  closeOverlay($("#filter-overlay"));
}
