import { clearRefinements } from "instantsearch.js/es/widgets";

export const clearFilters = $("#clear-refinements").length
  ? clearRefinements({
      container: "#clear-refinements",
      cssClasses: {
        button: "ml-6",
      },
      templates: {
        resetLabel({ hasRefinements }, { html }) {
          return html`<span class="text-link text-sm"
            >${hasRefinements ? "Clear filters" : ""}</span
          >`;
        },
      },
    })
  : null;
