import { currentRefinements as currentRefinementsWidget } from "instantsearch.js/es/widgets";

export const currentRefinements = $("#current-refinements").length
  ? currentRefinementsWidget({
      container: "#current-refinements",
      cssClasses: {
        root: "mt-3",
        noRefinementRoot: "hidden",
        list: "flex overflow-scroll",
        item: "flex-shrink-0 flex",
        label: "hidden",
        category: "pill-button relative flex justify-between items-center mr-1",
        categoryLabel: "pr-6",
        delete: "absolute inset-0 pr-6 text-right",
      },
      transformItems(items) {
        return items.filter(
          (item) =>
            item.attribute !== "cadFiles" &&
            item.attribute !== "price" &&
            item.refinements[0].label !== "Featured"
        );
      },
    })
  : null;
