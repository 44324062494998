import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";

const encodedCategories = {
  Eco: "Collections > Eco-Friendly",
};

const decodedCategories = Object.keys(encodedCategories).reduce((acc, key) => {
  const newKey = encodedCategories[key];
  const newValue = key;

  return {
    ...acc,
    [newKey]: newValue,
  };
}, {});

// Returns a slug from the category name.
function getCategorySlug(name) {
  // const encodedName = decodedCategories[name] || name;

  return name
    .replace(" ", "-")
    .toLowerCase()
    .split(">")
    .map(encodeURIComponent)
    .join("/");
}

// Returns a name from the category slug.
export function getCategoryName(slug) {
  // const decodedSlug = encodedCategories[slug] || slug;

  return slug.replace("-", " ").split("/").map(decodeURIComponent).join(" > ");
}

const router = history({
  createURL({ qsModule, routeState, location }) {
    const urlParts = location.href.match(/^(.*?)\/search/);
    const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

    // Set the category url
    const categoryPath =
      routeState.category && routeState.category != "Featured"
        ? `${getCategorySlug(routeState.category)}`
        : "";

    // Set the rest of the query url
    const queryParameters = {};

    if (routeState.query) {
      queryParameters.query = encodeURIComponent(routeState.query);
    }

    if (routeState.use) {
      queryParameters.use = routeState.use;
    }

    if (routeState.leadTime) {
      queryParameters.leadTime = routeState.leadTime;
    }

    const queryString = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    });

    // Return full url
    return `${baseUrl}furniture/${categoryPath}${queryString}`;
  },

  parseURL({ qsModule, location }) {
    const pathnameMatches = location.pathname.match(/furniture\/(.*?)\/?$/);
    const category = getCategoryName(
      (pathnameMatches && pathnameMatches[1]) || "Featured"
    );
    const categoryArray = category.length === 0 ? null : [category];

    const {
      query = "",
      use = "",
      leadTime = "",
    } = qsModule.parse(location.search.slice(1));

    return {
      query: decodeURIComponent(query),
      use: decodeURIComponent(use),
      leadTime: decodeURIComponent(leadTime),
      category: categoryArray,
    };
  },
});

const stateMapping = {
  stateToRoute(uiState) {
    const indexUiState = uiState[indexName];

    return {
      query: indexUiState.query,
      use: indexUiState.menu && indexUiState.menu.use,
      leadTime: indexUiState.menu && indexUiState.menu.leadTime,
      category:
        indexUiState.hierarchicalMenu &&
        indexUiState.hierarchicalMenu["productType.lvl0"] &&
        indexUiState.hierarchicalMenu["productType.lvl0"].join(">"),
    };
  },

  routeToState(routeState) {
    return {
      [indexName]: {
        query: routeState.query,
        menu: {
          use: routeState.use,
          leadTime: routeState.leadTime,
        },
        hierarchicalMenu: {
          "productType.lvl0": routeState.category,
        },
      },
    };
  },
};

const searchRouting = {
  router,
  stateMapping,
};

export default searchRouting;
