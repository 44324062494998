import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";

import {
  clearFilters,
  clearFiltersBtn,
  currentRefinements,
  productFiles,
  productLead,
  productPrice,
  products,
  productStyle,
  productTypes,
  productUse,
  searchBox,
  sortBy,
  stats,
  statsTotal,
} from "./widgets";

import searchRouting from "./search-routing";

const searchClient = algoliasearch(applicationId, searchApiKey);

const search = instantsearch({
  searchClient,
  indexName: indexName,
  routing: searchRouting,
  onStateChange({ uiState, setUiState }) {
    const typeFilter = uiState[indexName].hierarchicalMenu;
    const type =
      typeof typeFilter !== "undefined"
        ? typeFilter["productType.lvl0"]
        : ["Furniture"];
    const heading =
      type[type.length - 1] == "Featured" ? "Furniture" : type[type.length - 1];

    $("#totalRefinements").text(
      Object.keys([Object.keys(uiState)[0]]).length - 1
    );
    $("#shopTitle").text(heading);
    $("#shopDesc").hide();
    setUiState(uiState);
  },
});

if ($("#job-feed").length) {
  search.addWidgets([
    clearFilters,
    clearFiltersBtn,
    currentRefinements,
    productFiles,
    productLead,
    productPrice,
    products,
    productStyle,
    productTypes,
    productUse,
    searchBox,
    sortBy,
    stats,
    statsTotal,
  ]);
}

export default search;
