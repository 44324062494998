import * as menu from "./modules/navigation.js";
import * as btn from "./modules/buttons.js";
import search from "./algolia.js";
import handleScrollAnimation from "./modules/scrollAnimation.js";

let scrollElements = document.querySelectorAll(".js-scroll");

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Navigation
const nav = $("#mobile-navigation");

$("#menu-open").click(function () {
  menu.openNav(nav);
});

$(".menu-hide").click(function () {
  menu.closeNav(nav);

  $(".nav-step").each(function () {
    menu.closeNav($(this));
  });
});

$(".nav-next").click(function () {
  menu.openNav($(this).next(".nav-step"));
});

$(".nav-last").click(function () {
  menu.closeNav($(this).parent().parent());
});

$("#search-toggle").click(function () {
  menu.toggleSearch();
});

$(document).on("click", "#nav-overlay, #filter-overlay", function () {
  menu.closeSearch();
  menu.closeFilter();
});

$(window).resize(function () {
  if ($(window).width() < 850) {
    menu.closeSearch();
  }
});

$(".subnav-toggle").hover(
  function () {
    menu.closeSearch();
    menu.openOverlay($("#nav-overlay"));
  },
  function () {
    menu.closeOverlay($("#nav-overlay"));
  }
);

// Product filter
$(".filter-open").click(function () {
  menu.openFilter();
});

$(".filter-hide").click(function () {
  menu.closeFilter();
});

// Buttons
$(".accordion-toggle").click(function () {
  btn.toggleAccordion($(this));
});

$("#scroll-top").click(function () {
  btn.scrollToTop();
});

$("#scroll-details").click(function () {
  btn.scrollToDetails();
});

$(".vid-play").click(function () {
  btn.vidStart($(this));
});

// Algolia
if ($("#job-feed").length) {
  search.start();

  search.once("render", () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition, 10));
        sessionStorage.removeItem("scrollPosition");
      }, 100);
    }
  });

  search.on("render", () => {
    scrollElements = document.querySelectorAll(".js-scroll");
    handleScrollAnimation(scrollElements);
  });
}

// Algolia wishlist buttons
$(document).on("submit", ".wishlist-toggle", function (e) {
  let form = $(this);
  let data = form.serialize();

  $.ajax({
    type: "POST",
    url: "/",
    data: data,
    cache: true,
    dataType: "json",
    success: function (response) {
      if (response.items[0].action == "added") {
        form.find("svg").addClass("fill-black !stroke-black"); // item added
      } else {
        form
          .find("svg")
          .removeClass("fill-black !stroke-black")
          .addClass("stroke-current"); // item removed
      }

      $("#wishlistCounter").load(" #wishlistCounter > *");
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR);
    },
  });

  e.preventDefault();
});

// Slideshows
var testimonialSlider = new Swiper(".swiper.testimonials", {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  loop: true,
  threshold: 2,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    bulletClass: "swiper-pagination-bullet h-2 w-2 bg-white",
  },
});

var productSlider = new Swiper(".swiper.products", {
  slidesPerView: 2,
  spaceBetween: 10,
  slidesOffsetAfter: 32,
  threshold: 2,
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
    disabledClass: "opacity-25",
  },
  breakpoints: {
    768: {
      slidesPerView: 3.5,
      spaceBetween: 30,
    },
  },
  on: {
    slideChange: function (swiper) {
      var prods = swiper.$el.find(".swiper-slide");
      handleScrollAnimation(prods);
    },
  },
});

var projectSlider = new Swiper(".swiper.projects", {
  slidesPerView: 1.5,
  spaceBetween: 30,
  slidesOffsetAfter: 32,
  threshold: 2,
});

var productGallery = new Swiper(".swiper.product-gallery", {
  loop: true,
  threshold: 2,
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
    disabledClass: "opacity-25",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    bulletClass: "swiper-pagination-bullet h-2 w-2 bg-black",
  },
});

var imageGallery = new Swiper(".swiper.carousel", {
  loop: true,
  slidesPerView: "auto",
  spaceBetween: 10,
  threshold: 2,
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
    disabledClass: "opacity-25",
  },
  on: {
    slideChange: function (swiper) {
      var images = swiper.$el.find(".swiper-slide");
      handleScrollAnimation(images);
    },
  },
});

// Scroll animations
$(document).on("click", "#load-more", function () {
  htmx.on("htmx:afterSwap", function (event) {
    scrollElements = document.querySelectorAll(".js-scroll");
    handleScrollAnimation(scrollElements);
  });
});

$(window).on("load scroll", function () {
  handleScrollAnimation(scrollElements);
});

// Page transition
window.addEventListener("beforeunload", function () {
  $("main, footer").addClass("opacity-0 transition");
});
