import { hierarchicalMenu } from "instantsearch.js/es/widgets";

export const productTypes = $("#product-types").length
  ? hierarchicalMenu({
      container: "#product-types",
      attributes: ["productType.lvl0", "productType.lvl1"],
      limit: 30,
      sortBy: ["name"],
      cssClasses: {
        childList: "ml-2",
      },
      templates: {
        item(data, { html }) {
          return data.label == "Featured"
            ? ""
            : html`
                <a
                  class="checkbox group relative mb-1 flex cursor-pointer items-center"
                  href="${data.url}"
                >
                  <span
                    class="checkmark mr-2 flex h-4 w-4 items-center justify-center rounded-full border border-black/25 transition group-hover:border-black"
                  >
                    <span
                      class="${data.isRefined
                        ? ""
                        : "hidden"} radio h-2 w-2 rounded-full bg-black"
                    ></span>
                  </span>

                  <span class="text-sm">${data.label}</span>
                  <span class="ml-1 text-sm"
                    >(${data.count.toLocaleString()})</span
                  >
                </a>
              `;
        },
      },
    })
  : null;
