export function toggleAccordion(elem) {
  const accordion = elem.next(".accordion");
  const icon = elem.find("svg");

  icon.toggleClass("rotate-180 fill-black");
  accordion.slideToggle();
}

export function scrollToTop() {
  $("html, body").animate({ scrollTop: 0 }, "slow");
}

export function scrollToDetails() {
  $("html, body").animate(
    { scrollTop: $(".product-details").offset().top - 100 },
    "slow"
  );
}

export function vidStart(elem) {
  const video = elem.prev("video");
  const vid = video.get(0);

  vid.play();
  elem.addClass("hidden");

  video.click(function () {
    vid.pause();
    elem.removeClass("hidden");
  });

  video.on("ended", function () {
    elem.removeClass("hidden");
  });
}
