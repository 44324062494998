import { refinementList } from "instantsearch.js/es/widgets";

export const productStyle = $("#product-style").length
  ? refinementList({
      container: "#product-style",
      attribute: "productStyle",
      sortBy: ["name"],
      templates: {
        item(item, { html }) {
          const { url, label, count, isRefined } = item;

          return html`
            <a
              class="checkbox group relative mb-1 flex cursor-pointer items-center"
              href="${url}"
            >
              <span
                class="checkmark mr-2 flex h-4 w-4 items-center justify-center rounded-full border border-black/25 transition group-hover:border-black"
              >
                <span
                  class="${isRefined
                    ? ""
                    : "hidden"} radio h-2 w-2 rounded-full bg-black"
                ></span>
              </span>

              <span class="text-sm">${label}</span>
              <span class="ml-1 text-sm">(${count.toLocaleString()})</span>
            </a>
          `;
        },
      },
    })
  : null;
