import { clearRefinements } from "instantsearch.js/es/widgets";

export const clearFiltersBtn = $("#clear-refinements-btn").length
  ? clearRefinements({
      container: "#clear-refinements-btn",
      templates: {
        resetLabel({ hasRefinements }, { html }) {
          return html`Clear filters`;
        },
      },
    })
  : null;
