import instantsearch from "instantsearch.js";
import { infiniteHits } from "instantsearch.js/es/widgets";

export const products = $("#infinite-hits").length
  ? infiniteHits({
      container: "#infinite-hits",
      cache: instantsearch.createInfiniteHitsSessionStorageCache(),
      cssClasses: {
        emptyRoot: "text-lg text-center",
        item: "col-span-6 md:col-span-4",
        list: "grid grid-cols-12 gap-x-3 sm:gap-x-6 gap-y-12 mb-24",
        loadMore:
          "button w-60 mx-auto bg-transparent border-black/25 hover:border-black text-black",
        disabledLoadMore: "hidden",
      },
      templates: {
        item(hit, { html, components }) {
          return html`
            <div class="relative">
              <a
                href="/${hit.uri}"
                data-id="${hit.productId}"
                class="algoliaResultItem js-scroll fade-up relative"
                onClick=${() => {
                  sessionStorage.setItem("scrollPosition", window.pageYOffset);
                }}
              >
                <div class="group relative aspect-square w-full">
                  ${hit.productImages.map(
                    (image, index) =>
                      html`<img
                        data-src="${image}"
                        class="lazyload ${index === 0
                          ? ""
                          : "absolute inset-0 opacity-0 group-hover:opacity-100 transition"} h-full w-full !bg-white object-contain"
                      />`
                  )}
                  ${hit.labels.map(
                    (label, index) =>
                      html`<p
                        class="${hit.labelColour[index]} ${index === 0
                          ? "absolute top-2 left-2 bg-white px-3 py-1 text-sm"
                          : "hidden"}"
                      >
                        ${index === 0 ? label : ""}
                      </p>`
                  )}
                </div>

                <div class="justify-between md:flex">
                  <div class="mr-3 mt-4">
                    <p class="font-medium">${hit.title}</p>
                    <p class="hidden md:block">${hit.summary}</p>
                  </div>

                  <div class="flex flex-wrap md:mt-4 md:block md:text-right">
                    <p class="text-ff_red">
                      ${hit.salePrice != 0 ? "£" + hit.salePrice : ""}
                    </p>
                    <p
                      class="${hit.salePrice != 0
                        ? "line-through opacity-25 ml-3 md:ml-0"
                        : ""}"
                    >
                      £${hit.price}
                    </p>
                    <p class="block w-full text-sm sm:text-base md:hidden">
                      ${hit.summary}
                    </p>
                  </div>
                </div>
              </a>

              <form method="POST" class="wishlist-toggle">
                <input
                  type="hidden"
                  name="action"
                  value="wishlist/items/toggle"
                />
                <input
                  type="hidden"
                  name="${csrfTokenName}"
                  value="${csrfTokenValue}"
                />
                <input
                  type="hidden"
                  name="elementId"
                  value="${hit.productId}"
                />
                <input
                  type="hidden"
                  name="fields[quantity]"
                  id="quantity"
                  value="1"
                />

                <button
                  type="submit"
                  class="absolute right-2 top-2 rounded-full bg-white p-1 text-black/25 transition hover:text-black"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="transparent"
                    class="${listItems.includes(hit.productId)
                      ? "stroke-black fill-black"
                      : "stroke-current"} w-6 stroke-2 transition-colors hover:stroke-black"
                  >
                    <path
                      d="m12 21.35-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35Z"
                    />
                  </svg>
                </button>
              </form>
            </div>
          `;
        },
        empty(results, { html }) {
          return html`<p>
            No results for <q>${results.query}</q>. Try broadening your search.
          </p>`;
        },
        showMoreText(data, { html }) {
          return html`<span>Load more...</span>`;
        },
      },
    })
  : null;
