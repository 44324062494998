import { toggleRefinement } from "instantsearch.js/es/widgets";

export const productFiles = $("#product-files").length
  ? toggleRefinement({
      container: "#product-files",
      attribute: "cadFiles",
      cssClasses: {
        label: "flex items-center",
      },
      templates: {
        labelText({ onFacetValue, count }, { html }) {
          return html`<span class="text-sm"
            >Only show products with CAD files (${onFacetValue.count})</span
          >`;
        },
      },
    })
  : null;
